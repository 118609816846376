import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Image from "components/Image";
import "./NotFoundDetails.scss";

export const fragment = graphql`
  fragment NotFoundMarkdownRemarkFragment on Frontmatter {
    anchor
    header
    subheader
    imageFileName
  }
`;
export default function NotFoundDetails({ frontmatter }) {
  if (!frontmatter) return null;

  const { anchor, header, subheader, imageFileName } = frontmatter;

  return (
    <div id={anchor} className="notFound-wrapper">
      <Image className="notFound-img" fileName={imageFileName} alt={header || subheader} />
    </div>
  );
}
NotFoundDetails.propTypes = {
  frontmatter: PropTypes.shape({
    anchor: PropTypes.string,
    header: PropTypes.string,
    subheader: PropTypes.string,
    imageFileName: PropTypes.string,
  }),
};

NotFoundDetails.defaultProps = {
  frontmatter: null,
};
