import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { Footer, Navbar } from "views";
import { AlternativeNavigation, SEO } from "components";
import { findNodeBy } from "utils/breakDownAllNodes";
import NotFoundDetails from "views/Sections/404";
import "utils/fixFontAwesome";

import "../style/main.scss";

export const query = graphql`
  query notFoundQuery($langKey: String!) {
    site {
      meta: siteMetadata {
        keywords
        description
      }
    }
    content: allMarkdownRemark(filter: { fields: { langKey: { eq: $langKey } } }) {
      nodes {
        i18n: frontmatter {
          ...NavbarMarkdownRemarkFragment
          ...LandingsNavigationMarkdownRemarkFragment
          ...NotFoundMarkdownRemarkFragment
          ...FooterMarkdownRemarkFragment
          ...ContactMarkdownRemarkFragment
          ...ContactUsMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const NotFoundPage = ({ data, pageContext }) => {
  const {
    site: {
      meta: { keywords, description },
    },
    content: { nodes },
  } = data;

  const navBarNode = findNodeBy(nodes, /navbar\./i);
  const footerNode = findNodeBy(nodes, /footer\./i);
  const notFoundNode = findNodeBy(nodes, /notFound\./i);
  const sitemapNode = findNodeBy(nodes, /sitemap\./i);
  const contactUsNode = findNodeBy(nodes, /contactus\./i);
  const seo = { lang: pageContext.langKey, keywords, description };

  return (
    <div>
      <SEO title="NotFound" {...seo} />
      <Navbar frontmatter={navBarNode.i18n} langKey={pageContext.langKey}>
        <AlternativeNavigation context={pageContext} frontmatter={sitemapNode.i18n} />
      </Navbar>
      {/* page content */}
      <NotFoundDetails frontmatter={notFoundNode.i18n} />

      <Footer
        frontmatter={footerNode.i18n}
        navigationNode={sitemapNode.i18n}
        contactUsNode={contactUsNode.i18n}
      />
    </div>
  );
};

NotFoundPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

NotFoundPage.defaultProps = {
  pageContext: {
    langKey: "en",
    defaultLang: "en",
    langTextMap: {},
  },
};
export default NotFoundPage;
